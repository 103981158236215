<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-lg-2 col-md-12 mb-3">
          <div
            class="nav flex-column nav-pills me-3"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <router-link class="nav-link active" to="/config/users">Usuários</router-link>
            <!-- <router-link class="nav-link" to="/config">Outro</router-link> -->
            <!-- <router-link
              to="/config/users"
              class="nav-link active"
              id="v-pills-users-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-users"
              type="button"
              role="tab"
              aria-controls="v-pills-users"
              aria-selected="true"
            >
              Usuários
            </router-link> -->
            <!-- <button
              class="nav-link"
              id="v-pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-profile"
              type="button"
              role="tab"
              aria-controls="v-pills-profile"
              aria-selected="false"
            >
              Profile
            </button>
            <button
              class="nav-link"
              id="v-pills-messages-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-messages"
              type="button"
              role="tab"
              aria-controls="v-pills-messages"
              aria-selected="false"
            >
              Messages
            </button>
            <button
              class="nav-link"
              id="v-pills-settings-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-settings"
              type="button"
              role="tab"
              aria-controls="v-pills-settings"
              aria-selected="false"
            >
              Settings
            </button> -->
          </div>
        </div>
        <div class="col-lg-9 col-md-12">
          <div class="tab-content" id="v-pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="v-pills-home"
              role="tabpanel"
              aria-labelledby="v-pills-home-tab"
            >
              <div class="card">
                <div class="card-body">
                  <router-view />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.$router.push("/config/users");
  }
}
</script>

<style scoped>
.nav-link {
  text-align: left;
}
</style>